/* this is for styling custom component like glassmorphism container for login*/
.login {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
}

.background {
  background: rgba(30, 118, 43, 0.1) url("../assets/nature.jpeg");
  background-blend-mode: soft-light;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.login-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* image from public folder*/
  background-image: url("../assets/auth/grass.png");
}

/* 73, 167, 80, */
.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

/* scroll indicator style */
.custom-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border: solid rgba(73, 167, 80, 0);
  background-clip: padding-box;
  background-color: transparent;
  border-radius: 0px;
  box-shadow: inset -1px -1px 0px rgba(73, 167, 80, 0.5),
    inset 1px 1px 0px rgba(73, 167, 80, 0.5);
  -webkit-border-radius: 0px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(73, 167, 80, 0.5),
    inset 1px 1px 0px rgba(73, 167, 80, 0.5);
}

.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.custom-scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}

.scrollbarless::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbarless::-webkit-scrollbar {
  width: 5px;
}

.scrollbarless::-webkit-scrollbar-thumb {
  border: solid rgba(73, 167, 80, 0);
  background-clip: padding-box;
  background-color: rgba(73, 167, 80, 0.3);
  border-radius: 10px;
  box-shadow: inset -1px -1px 0px rgba(73, 167, 80, 0.5),
    inset 1px 1px 0px rgba(73, 167, 80, 0.5);
  -webkit-border-radius: 10px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(73, 167, 80, 0.5),
    inset 1px 1px 0px rgba(73, 167, 80, 0.5);
}

.scrollbarless::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.scrollbarless::-webkit-scrollbar-corner {
  background-color: transparent;
}

.custom-file-input::-webkit-file-upload-button {
  /* visibility: hidden; */
}

.custom-file-input::before {
  content: "Select image";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 5px;
  padding: 10px 10px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  margin-right: -70px;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* scroll indicator style */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: tr;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border: solid rgba(73, 167, 80, 0);
  background-clip: padding-box;
  background-color: rgba(73, 167, 80, 0.3);
  border-radius: 10px;
  box-shadow: inset -1px -1px 0px rgba(73, 167, 80, 0.5),
    inset 1px 1px 0px rgba(73, 167, 80, 0.5);
  -webkit-border-radius: 10px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(73, 167, 80, 0.5),
    inset 1px 1px 0px rgba(73, 167, 80, 0.5);
}

::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.stickScroll {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
}

.stickScrollRight {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
}


